<template>
  <div>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        :to="{ name: this.$route.matched[2].meta.routeDirectDispute }"
        exact
        :class="isActiveDirect && 'activeCustom'"
        :exact-active-class="'active'"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >DIRECT
      </b-nav-item>
      <b-nav-item
        :to="{ name: this.$route.matched[2].meta.routeLettersDispute }"
        exact
        :class="isActiveLetters && 'activeCustom'"
        :exact-active-class="'active'"
        :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
        >LETTERS
      </b-nav-item>
    </b-nav>
    <div class="border-top-info pt-2 border-3 border-table-radius px-0">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//components

export default {
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    isActiveDirect() {
      let validRoutes = [
        "ds-pending-directs-round",
        "ds-completed-directs-round",
        "ds-deleted-directs-round",
      ];

      return validRoutes.includes(this.$route.name);
    },

    isActiveLetters() {
      let validRoutes = [
        "ds-letters-dispute-to-send-pending",
        "ds-letters-dispute-to-send-in-queue",
        "ds-letters-dispute-to-send-returned",
        "ds-letters-dispute-deleted",
        "ds-letters-dispute-expired",
        "ds-letters-dispute-completed-in-correspondence",
        "ds-letters-dispute-completed-sent",
      ];

      return validRoutes.includes(this.$route.name);
    },
  },
};
</script>

<style scoped>
.activeCustom a {
  background: #8f5fe8 !important;
  color: white !important;
}
</style>
